'use client';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from '@edeeone/juan-core/components/button/Button';
import { LogoImage } from '@edeeone/juan-core/components/logoImage/LogoImage';
import { SearchForm } from '@edeeone/juan-core/components/searchForm/SearchForm';
import { Title } from '@edeeone/juan-core/components/title/Title';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { useLinkRoute } from '@edeeone/juan-core/routes/useLinkRoute';
import Link from 'next/link';
import { errorViewClassNames } from './ErrorView.styles';
import { ButtonType, getPageViewFromStatusCode, } from './getThePageViewFromStatusCode';
export default function ErrorView({ statusCode, reload, showLogo: showLogoProps, showButton = true, digest, hideErrorCode, }) {
    const errorPageView = getPageViewFromStatusCode(statusCode);
    const loginPathData = useLinkRoute({ id: '/edee-registration/login' });
    const buttonProps = errorPageView.button === ButtonType.reload
        ? { onClick: reload }
        : { href: '/' };
    const { t } = useI18n('ErrorPage');
    const finalStyles = errorViewClassNames();
    const showStatusCode = !hideErrorCode && errorPageView.showCode;
    const showLogo = showLogoProps ?? errorPageView.showLogo;
    return (_jsxs(_Fragment, { children: [showLogo && (_jsx("div", { className: finalStyles.logoContainer, children: _jsx(LogoImage, {}) })), _jsx("div", { id: "layoutMainContent", className: finalStyles.container, children: _jsx("div", { className: finalStyles.content, children: _jsxs("div", { className: finalStyles.page, children: [_jsx("img", { className: finalStyles.page_icon, src: `/img/error/${errorPageView.icon}.svg`, alt: "" }), _jsx(Title, { as: "h1", design: "primary", styles: { container__custom: finalStyles.page_title }, children: t(`title.${errorPageView.title}`) }), _jsx("p", { className: finalStyles.page_perex, children: t(`description.${errorPageView.description}`, {
                                    a: (chunks) => {
                                        return (_jsx(Link, { href: loginPathData?.url, shallow: true, legacyBehavior: true, children: _jsx("a", { children: chunks }) }));
                                    },
                                }) }), showButton && (_jsx("div", { className: finalStyles.page_btnContainer, children: _jsx(Button, { ...buttonProps, children: t(`button.${errorPageView.button}`) }) })), errorPageView.showSearchBox && (_jsx("div", { className: finalStyles.page_searchBox, children: _jsx(SearchForm, { showLabel: false, placeholder: t(`placeholder.trySearch`) }) })), showStatusCode && (_jsx("p", { className: finalStyles.page_code, children: t(`statusCode`, { statusCode }) })), digest && (_jsxs("p", { className: finalStyles.page_code, children: ["Digest: ", digest] }))] }) }) })] }));
}
