export var ButtonType;
(function (ButtonType) {
    ButtonType["homePage"] = "homePage";
    ButtonType["reload"] = "reload";
})(ButtonType || (ButtonType = {}));
export function getPageViewFromStatusCode(statusCode) {
    const errorPageView = {
        showLogo: false,
        showSearchBox: false,
        showCode: true,
        icon: 'error400',
        title: 'somethingIsWrong',
        description: 'failedLoading',
        button: ButtonType.homePage,
    };
    if (statusCode.startsWith('4')) {
        switch (statusCode) {
            case '400': {
                break;
            }
            case '401': {
                errorPageView.title = 'noPermission';
                errorPageView.description = 'registeredOnly';
                errorPageView.icon = 'error401';
                break;
            }
            case '403': {
                errorPageView.title = 'noPermission';
                errorPageView.description = 'noPermission';
                errorPageView.icon = 'error401';
                break;
            }
            case '404': {
                errorPageView.title = 'notFound';
                errorPageView.description = 'notFound';
                errorPageView.showSearchBox = true;
                errorPageView.icon = 'error404';
                break;
            }
            case '408': {
                errorPageView.title = 'timeout';
                errorPageView.description = 'timeout';
                errorPageView.icon = 'error408';
                break;
            }
            default: {
                errorPageView.description = 'somethingWrong';
                break;
            }
        }
    }
    else {
        errorPageView.showLogo = true;
        errorPageView.button = ButtonType.reload;
        if (statusCode === '500') {
            errorPageView.description = 'somethingWrong';
        }
        else if (statusCode === '503-install') {
            errorPageView.title = 'installing';
            errorPageView.description = 'installing';
            errorPageView.icon = 'error503install';
            errorPageView.showCode = false;
        }
        else {
            errorPageView.description = 'unavailable';
            errorPageView.icon = 'error503';
        }
    }
    return errorPageView;
}
