import { getMemoizedFinalClassnames } from '../../hooks/useFinalClassnames';
export const titleDefaultClassNames = /* tw */ {
    container: '',
    container__custom: '',
    container__default: '',
    container__modal: '',
    container__lineClamp: 'line-clamp-2',
    badge: 'font-normal',
    titleLink: 'transition-common hover:underline',
    // h1 nadpisy stránek (detail produktu, kategorie, značka, skupina, košík, detail objednávky, oblíbené, porovnání, historie produktů, stránky v mém účtu)
    primary: {
        container: 'page-title grid-in-title text-2xl font-semibold text-black ',
        container__default: 'sm:text-3xl xl:text-4xl mt-4 mb-8 md:mb-12',
        container__modal: 'mb-4',
    },
    // h2/h3 - podnadpisy (související produkty, podnapisy v detailu produktu, podnapisy v detailu objednávky, podnapisy v Mém účtu)
    secondary: {
        container: 'text-xl mb-8 md:text-2xl md:mb-12 font-semibold text-black text-center tracking-wide',
    },
    // nadpisy modálních oken, podnadpisy v detailu objednávky, nadpis postranního sloupce v košíku, nadpis ve výpisu objednávek, větší nadpis ve stavu objednávky, hlavní nadpis menu Můj účet
    tertiary: {
        container: 'text-lg font-semibold text-black',
    },
    // h1  modální okno - menší, menší nadpis ve stavu objednávky
    quaternary: {
        container: 'text-base font-semibold text-black',
    },
    // název produktu ve standardní produktové dlaždici, název produktu v produktové tabulce (objednávka, bonusový produkt), název produktu ve výpisu objednávky
    quinary: {
        container: 'text-tile font-semibold text-gray-70',
    },
    // název produktu ve sliderové produktové dlaždici, název produktu v produktové tabulce (košík, sdílený košík), název sekcí v Osobní a přihlašovací údaje
    senary: {
        container: 'text-sm text-gray-70 font-medium',
    },
    // název produktu ve sticky liště na detailu produktu
    septenary: {
        container: 'text-xxs text-gray-70 font-normal',
    },
    // nadpis adres na detailu objednávky a přehledu objednávky
    octonary: {
        container: 'text-xs text-black font-semibold uppercase',
    },
};
export function titleClassNames(...args) {
    return getMemoizedFinalClassnames('titleClassNames', titleDefaultClassNames, ...args);
}
