import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { titleClassNames } from '@edeeone/juan-core/components/title/title.styles';
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
const ConditionalWrapper = ({ condition, wrapper, children }) => {
    // meets the condition only in evita-catalog Title component
    return condition ? wrapper(children) : children;
};
export const Title = ({ as = 'h1', text = '', id, children, design = 'primary', styles, inModal = false, badge, url, showUrl = true, lineClamp = false, linkWrapper, }) => {
    const Element = as;
    const finalStyles = titleClassNames(styles, design);
    return (_jsx(Element, { className: twMergeJuan(finalStyles.container, finalStyles.container__custom, !inModal && finalStyles.container__default, inModal && finalStyles.container__modal, lineClamp && finalStyles.container__lineClamp), id: id, children: _jsxs(ConditionalWrapper, { condition: url && showUrl && linkWrapper, wrapper: linkWrapper, children: [children, text, badge && _jsxs("span", { className: finalStyles.badge, children: [" (", badge, ")"] })] }) }));
};
