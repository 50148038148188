import { getMemoizedFinalClassnames } from '../../hooks/useFinalClassnames';
export const searchFormDefaultClassNames = /* tw */ {
    input: 'p-2',
    icon: 'hidden sm:block ml-[24px] w-8 h-8 fill-black mr-5 fill-gray-30 shrink-0',
    btn: 'sm:hidden',
    btnSm: 'hidden sm:block',
};
export function searchFormClassNames(...args) {
    return getMemoizedFinalClassnames('searchFormClassNames', searchFormDefaultClassNames, ...args);
}
